<template>
	<div class="modal-body">
		<div class="row">
			<template v-if="requirement.requirement_reason_code == 'NEW_POSITION'">
				<!-- MOTIVO DE REQUERIMIENTO -->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Motivo de requerimiento</label>
					<p>{{ requirement.requirement_reason_name }}</p>
				</div>
				<!-- EMPRESA-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
					<label class="col-form-label">Empresa</label>
					<p>{{ requirement.enterprise_denomination }}</p>
				</div>
				<!-- SEDE-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Sede</label>
					<p>{{ requirement.campu_name }}</p>
				</div>
				<!-- PUESTO -->
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
					<label class="col-form-label">Puesto</label>
					<p>{{ requirement.job_position_name }}</p>
				</div>
				<!-- MODALIDAD DE CONTRATO-->
				<div class="col-6 col-sm-6 col-md-5 col-lg-6 col-xl-4">
					<label class="col-form-label">Modalidad de contrato</label>
					<p>{{ requirement.contract_name }}</p>
				</div>
				<!-- GENERO-->
				<div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-4">
					<label class="col-form-label">Género</label>
					<p>{{ requirement.gender_name }}</p>
				</div>
				<!-- RANGO DE EDAD-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Rango de edad</label>
					<p>{{ requirement.age_range }}</p>
				</div>
				<!-- NUMERO DE VACANTES-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Num. de vacantes</label>
					<p>{{ requirement.vacancies_number }}</p>
				</div>
				<!-- REMUNERACION-->
				<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Remuneración</label>
					<p>{{ `S/${requirement.remuneration}` }}</p>
				</div>
				<!-- BENEFICIOS -->
				<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Beneficios</label>
					<template v-if="requirement.benefits.length">
						<p v-for="(b, i) in requirement.benefits" :key="i" class="mb-1">{{ `${b.benefit_type_name}: S/${b.amount}` }}</p>
					</template>
					<template v-else>
						<p>Ninguna</p>
					</template>
				</div>
				<!-- JUSTIFICACIÓN DEL NUEVO PUESTO-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
					<label class="col-form-label">Justificación del nuevo puesto</label>
					<p>{{ requirement.justification }}</p>
				</div>
				<!-- FUNCIONES PRINCIPALES-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Funciones principales</label>
					<p v-html="requirement.principal_function != null ? requirement.principal_function : '---'"></p>
				</div>
				<!-- REQUISITOS-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Requisitos</label>
					<p v-html="requirement.requisites != null ? requirement.requisites : '---'"></p>
				</div>
			</template>
			<template v-else-if="requirement.requirement_reason_code == 'LICENSE_OR_REPLACEMENT'">
				<!-- MOTIVO DE REQUERIMIENTO -->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Motivo de requerimiento</label>
					<p>{{ requirement.requirement_reason_name }}</p>
				</div>
				<!-- EMPRESA-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
					<label class="col-form-label">Empresa</label>
					<p>{{ requirement.enterprise_denomination }}</p>
				</div>
				<!-- SEDE-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Sede</label>
					<p>{{ requirement.campu_name }}</p>
				</div>
				<!-- PUESTO -->
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
					<label class="col-form-label">Puesto</label>
					<p>{{ requirement.job_position_name }}</p>
				</div>
				<!-- MODALIDAD DE CONTRATO-->
				<div class="col-6 col-sm-6 col-md-5 col-lg-6 col-xl-4">
					<label class="col-form-label">Modalidad de contrato</label>
					<p>{{ requirement.contract_name }}</p>
				</div>
				<!-- GENERO-->
				<div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-4">
					<label class="col-form-label">Género</label>
					<p>{{ requirement.gender_name }}</p>
				</div>
				<!-- RANGO DE EDAD-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Rango de edad</label>
					<p>{{ requirement.age_range }}</p>
				</div>
				<!-- REEMPLAZA-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Reemplaza</label>
					<p>{{ requirement.replaces_to }}</p>
				</div>
				<!-- REMUNERACION-->
				<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Remuneración</label>
					<p>{{ `S/${requirement.remuneration}` }}</p>
				</div>
				<!-- BENEFICIOS -->
				<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<label class="col-form-label">Beneficios</label>
					<template v-if="requirement.benefits.length">
						<p v-for="(b, i) in requirement.benefits" :key="i" class="mb-1">{{ `${b.benefit_type_name}: S/${b.amount}` }}</p>
					</template>
					<template v-else>
						<p>Ninguna</p>
					</template>
				</div>
				<!-- JUSTIFICACIÓN DEL NUEVO PUESTO-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
					<label class="col-form-label">Justificación del nuevo puesto</label>
					<p>{{ requirement.justification }}</p>
				</div>
				<!-- FUNCIONES PRINCIPALES-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Funciones principales</label>
					<p v-html="requirement.principal_function != null ? requirement.principal_function : '---'"></p>
				</div>
				<!-- REQUISITOS-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Requisitos</label>
					<p v-html="requirement.requisites != null ? requirement.requisites : '---'"></p>
				</div>
			</template>
			<template v-else-if="requirement.requirement_reason_code == 'CESSATION'">
				<!-- MOTIVO DE REQUERIMIENTO -->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Motivo de requerimiento</label>
					<p>{{ requirement.requirement_reason_name }}</p>
				</div>
				<!-- EMPRESA-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
					<label class="col-form-label">Empresa</label>
					<p>{{ requirement.enterprise_denomination }}</p>
				</div>
				<!-- SEDE-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Sede</label>
					<p>{{ requirement.campu_name }}</p>
				</div>
				<!-- PUESTO -->
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
					<label class="col-form-label">Puesto</label>
					<p>{{ requirement.job_position_name }}</p>
				</div>
				<!-- MODALIDAD DE CONTRATO-->
				<div class="col-6 col-sm-6 col-md-5 col-lg-6 col-xl-4">
					<label class="col-form-label">Modalidad de contrato</label>
					<p>{{ requirement.contract_name }}</p>
				</div>
				<!-- GENERO-->
				<div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-4">
					<label class="col-form-label">Género</label>
					<p>{{ requirement.gender_name }}</p>
				</div>
				<!-- FECHA DE CESE-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Fecha de cese</label>
					<p>{{ requirement.cessation_date_formatted }}</p>
				</div>
				<!-- REEMPLAZA-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Reemplaza</label>
					<p>{{ requirement.replaces_to }}</p>
				</div>
				<!-- REMUNERACION-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Remuneración</label>
					<p>{{ `S/${requirement.remuneration}` }}</p>
				</div>
				<!-- BENEFICIOS -->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Beneficios</label>
					<template v-if="requirement.benefits.length">
						<p v-for="(b, i) in requirement.benefits" :key="i" class="mb-1">{{ `${b.benefit_type_name}: S/${b.amount}` }}</p>
					</template>
					<template v-else>
						<p>Ninguna</p>
					</template>
				</div>
				<!-- FUNCIONES PRINCIPALES-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Funciones principales</label>
					<p v-html="requirement.principal_function != null ? requirement.principal_function : '---'"></p>
				</div>
				<!-- REQUISITOS-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Requisitos</label>
					<p v-html="requirement.requisites != null ? requirement.requisites : '---'"></p>
				</div>
			</template>
			<template v-else-if="requirement.requirement_reason_code == 'OTHERS'">
				<!-- MOTIVO DE REQUERIMIENTO -->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Motivo de requerimiento</label>
					<p>{{ requirement.requirement_reason_name }}</p>
				</div>
				<!-- EMPRESA-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
					<label class="col-form-label">Empresa</label>
					<p>{{ requirement.enterprise_denomination }}</p>
				</div>
				<!-- SEDE-->
				<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
					<label class="col-form-label">Sede</label>
					<p>{{ requirement.campu_name }}</p>
				</div>
				<!-- PUESTO -->
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
					<label class="col-form-label">Puesto</label>
					<p>{{ requirement.job_position_name }}</p>
				</div>
				<!-- MODALIDAD DE CONTRATO-->
				<div class="col-6 col-sm-6 col-md-5 col-lg-6 col-xl-4">
					<label class="col-form-label">Modalidad de contrato</label>
					<p>{{ requirement.contract_name }}</p>
				</div>
				<!-- GENERO-->
				<div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-4">
					<label class="col-form-label">Género</label>
					<p>{{ requirement.gender_name }}</p>
				</div>
				<!-- FECHA DE CESE-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Rango de edad</label>
					<p>{{ requirement.age_range }}</p>
				</div>
				<!-- REEMPLAZA-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Num. de vacantes</label>
					<p>{{ requirement.vacancies_number }}</p>
				</div>
				<!-- REMUNERACION-->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Remuneración</label>
					<p>{{ `S/${requirement.remuneration}` }}</p>
				</div>
				<!-- BENEFICIOS -->
				<div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-3">
					<label class="col-form-label">Beneficios</label>
					<template v-if="requirement.benefits.length">
						<p v-for="(b, i) in requirement.benefits" :key="i" class="mb-1">{{ `${b.benefit_type_name}: S/${b.amount}` }}</p>
					</template>
					<template v-else>
						<p>Ninguna</p>
					</template>
				</div>
				<!-- FUNCIONES PRINCIPALES-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Funciones principales</label>
					<p v-html="requirement.principal_function != null ? requirement.principal_function : '---'"></p>
				</div>
				<!-- REQUISITOS-->
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<label class="col-form-label">Requisitos</label>
					<p v-html="requirement.requisites != null ? requirement.requisites : '---'"></p>
				</div>
			</template>
		</div>
		<div class="row">
			<div class="col-md-12 text-right">
				<button @click="mtd_close" class="btn btn-sm btn-secondary">Cerrar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
	},
	data() {
		return {
			requirement: {
				age_range: null,
				benefits: [],
				schedule: null,
				campu_name: null,
				cessation_date: null,
				cessation_date_formatted: null,
				contract_name: null,
				enterprise_denomination: null,
				requirement_reason_name: null,
				requirement_reason_code: null,
				gender_name: null,
				id: null,
				job_position_name: null,
				justification: null,
				principal_function: null,
				remuneration: null,
				replaces_to: null,
				requirement_number: null,
				requisites: null,
				vacancies_number: null,
			},
			errorsBackend: {},
			methods: {
				init: {
					view: 'this.mtd_onView()',
				},
				action: {
					view: 'this.mtd_detail()',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onView() {
			this.mtd_getShow();
		},
		mtd_getShow() {
			this.get({ url: `${this.url_api}/requirement/${this.propInfo.data.row.id}/show` })
				.then(res => {
					if (res.state == 'success') {
						this.mtd_setRequirement(res.requirement);
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_setRequirement(requirement) {
			if (requirement.age_range != null) this.requirement.age_range = requirement.age_range;
			if (requirement.benefits.length) this.requirement.benefits = requirement.benefits;
			if (requirement.schedule != null) this.requirement.schedule = requirement.schedule;
			if (requirement.campu_name != null) this.requirement.campu_name = requirement.campu_name;
			if (requirement.cessation_date != null) this.requirement.cessation_date = requirement.cessation_date;
			if (requirement.cessation_date_formatted != null) this.requirement.cessation_date_formatted = requirement.cessation_date_formatted;
			if (requirement.contract_name != null) this.requirement.contract_name = requirement.contract_name;
			if (requirement.enterprise_denomination != null) this.requirement.enterprise_denomination = requirement.enterprise_denomination;
			if (requirement.requirement_reason_name != null) this.requirement.requirement_reason_name = requirement.requirement_reason_name;
			if (requirement.requirement_reason_code != null) this.requirement.requirement_reason_code = requirement.requirement_reason_code;
			if (requirement.gender_name != null) this.requirement.gender_name = requirement.gender_name;
			if (requirement.id != null) this.requirement.id = requirement.id;
			if (requirement.job_position_name != null) this.requirement.job_position_name = requirement.job_position_name;
			if (requirement.justification != null) this.requirement.justification = requirement.justification;
			if (requirement.principal_function != null) this.requirement.principal_function = requirement.principal_function;
			if (requirement.remuneration != null) this.requirement.remuneration = requirement.remuneration;
			if (requirement.replaces_to != null) this.requirement.replaces_to = requirement.replaces_to;
			if (requirement.requirement_number != null) this.requirement.requirement_number = requirement.requirement_number;
			if (requirement.requisites != null) this.requirement.requisites = requirement.requisites;
			if (requirement.vacancies_number != null) this.requirement.vacancies_number = requirement.vacancies_number;
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close() {
			this.$emit('close');
		},
		mtd_send() {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
