<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel" @action-create="mtd_goCreateRequirement"></page-header>
				<div class="inner-main-body">
					<page-body
						:propHeader="dataTable.header"
						:propBody="dataTable.body"
						:propModel="dataModel"
						@action-next-pagination="mtd_nextPagination"
						@details-requirement-modal="mtd_showDetailsRequirementsModal"
						@state-requirement-interface="mtd_goStateRequirement">
						<template slot="content-filter">
							<component :is="filter_component" @emit-init-pagination="mtd_initPagination" :prop-execute="executeFilter" :page="dataTable.header.page"></component>
						</template>
					</page-body>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'details-requirement-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MainFilter from './components/MainFilter';
import DetailsRequirementModal from '@/pages/shared/requirements/Details.vue';

export default {
	components: {
		MainFilter,
		DetailsRequirementModal,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				getter: 'administrator_requirements/requirements_index',
				prefixPermission: '',
				alias: 'Mis requerimientos',
				icon: 'fas fa-book-reader',
			},
			page: {
				header: {
					menu: ['Historial'],
				},
				buttons: {
					create: true,
					import: false,
					export: false,
				},
			},
			dataTable: {
				header: {
					title: 'Requerimientos',
					filter: false,
					page: 1,
				},
				body: {
					cols: [
						{
							name: 'requirement_number',
							alias: 'Código',
							width: '',
						},
						{
							name: 'user_full_names',
							alias: 'Registrado por',
							width: '',
						},
						{
							name: 'requirement_reason_name',
							alias: 'Motivo de Requerimiento',
							width: '',
						},
						{
							name: 'job_position_name',
							alias: 'Puesto de trabajo',
							width: '',
						},
						{
							name: 'step_full_name',
							alias: 'Etapa',
							width: '',
						},
						{
							name: 'created_at_formatted',
							alias: 'F. Registro',
							width: '',
						},
					],
					buttons: {
						edit: false,
						remove: false,
						width: '200px',
					},
					myButtons: [
						{
							text: '<i class="fas fa-eye"></i>',
							class: 'btn-xs btn-warning',
							tooltip: 'Ver detalles',
							component: 'details-requirement-modal',
							can: '',
						},
						{
							text: "<i class='fas fa-battery-quarter'></i>",
							class: 'btn-xs btn-primary',
							tooltip: 'Estado',
							component: 'state-requirement-interface',
							can: '',
						},
						// {
						// 	text: '<i class="fas fa-users"></i>',
						// 	class: 'btn-info btn-xs',
						// 	tooltip: 'Ver postulantes',
						// 	component: '',
						// 	can: '',
						// 	showIf: {
						// 		stored_by_authenticated_user: 1,
						// 	},
						// },
					],
					sonWindow: {
						component: null,
						row: null,
					},
				},
			},
			modals: [
				{
					id: 'details-requirement-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'details-requirement-modal',
						idHash: '#details-requirement-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit', 'update']),
		mtd_showDetailsRequirementsModal(row) {
			const main_form = this.modals.find(el => el.id == 'details-requirement-modal');

			main_form.slot.component = 'details-requirement-modal';
			main_form.slot.data = {
				row: row,
				method: 'view',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-lg';
			main_form.config.position = 'modal-left';
			main_form.config.title = `<span class='fas fa-book-reader'></span> Requerimiento ${row.requirement_number}`;
		},
		mtd_goCreateRequirement: function () {
			this.$router.push({ name: 'administrator-requirements-create' });
		},
		mtd_goStateRequirement(row) {
			this.$router.push({ name: 'requirement', params: { requirement_number: row.requirement_number } });
		},
		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},

		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},
		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
	},
	beforeDestroy() {
		this.execute_commit({ mutation: 'administrator_requirements/REQUIREMENTS_INDEX__CLEAR_ALL' });
	},
};
</script>
